<script>
    export let offset = 0;
</script>

<button on:click|preventDefault style="top:{offset}px; right:{offset}px;">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 10.3619L2.00003 2.36197" stroke="white" stroke-width="3" stroke-linecap="round"/>
        <path d="M10 2.36182L2 10.3618" stroke="white" stroke-width="3" stroke-linecap="round"/>
    </svg>
</button>

<style lang="scss">
  button {
    position: absolute;
    width: 33px;
    height: 33px;
    padding: 11px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 100;
    transition: all 0.3s ease;
    border-radius: 10px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
</style>